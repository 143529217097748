import { FC, useState } from 'react';
import EntityDrawerContainer from '../entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from '../entity-drawer-layout/EntityDrawerHeader';
import EditRecurringEvent from './EditRecurringEvent';
import RecurringEventHistory from './components/RecurringEventHistory';
import { Box, Tab, Tabs } from '@mui/material';
import Segment from '@mui/icons-material/Segment';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import HistoryOutlined from '@mui/icons-material/HistoryOutlined';
import UpdatedStyleWrapper from '../updated-style-wrapper';

type RecurringEventDetailsProps = {
    id: number | string;
    entry_date: string;
    onClose: () => void;
};

const RecurringEventDetails: FC<RecurringEventDetailsProps> = ({ id, entry_date, onClose }) => {
    const [tab, setTab] = useState<'details' | 'attendees' | 'history'>('details');

    return (
        <UpdatedStyleWrapper>
            <EntityDrawerContainer>
                <EntityDrawerHeader title="Event Details" onClose={onClose} />
                <Box
                    sx={{
                        width: '100%',
                        '& .MuiTab-root': { minWidth: '73px', minHeight: '49px', py: 1, px: 2, flexGrow: 1 },
                        '& .MuiTabs-root': {
                            minHeight: '50px'
                        }
                    }}
                >
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        orientation="horizontal"
                        onChange={(_, v) => setTab(v)}
                        value={tab}
                        indicatorColor="secondary"
                        textColor="secondary"
                    >
                        <Tab value="details" icon={<Segment />} iconPosition="start" />
                        <Tab value="attendees" icon={<PersonOutlineOutlined />} iconPosition="start" />
                        <Tab value="history" icon={<HistoryOutlined />} iconPosition="start" />
                    </Tabs>
                </Box>
                {tab === 'details' ? <EditRecurringEvent id={id} entry_date={entry_date} onClose={onClose} /> : null}
                {tab === 'attendees' ? <EditRecurringEvent id={id} entry_date={entry_date} onClose={onClose} customersOnly /> : null}
                {tab === 'history' ? <RecurringEventHistory id={id} /> : null}
            </EntityDrawerContainer>
        </UpdatedStyleWrapper>
    );
};

export default RecurringEventDetails;
