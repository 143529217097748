import { IService, PaymentType, ServiceLocationType } from '../../models/IService';
import getArrayIntersections from '../get-array-intersections';
import { IEmployee } from '../../models/IEmployee';

type ServiceNameReturn = {
    fullValue: string;
    firstItemValue: string;
    counter?: number;
};

type AppointmentServiceType = IService[];

export const sortServicesArray = (service: AppointmentServiceType): IService[] =>
    [...service].sort((a, b) => (a.pivot?.id ?? 0) - (b.pivot?.id ?? 0));

export const getServiceName = (service: Pick<IService, 'name'>[]): ServiceNameReturn => {
    if (service.length) {
        const fullValue = service.map(({ name }) => name).join(', ');
        const firstItemValue = service[0].name;
        const counter = service.length - 1;

        return { fullValue, firstItemValue, counter };
    }

    return {
        fullValue: 'Unknown Service',
        firstItemValue: 'Unknown Service',
        counter: 0
    };
};

export const getServiceImages = (service: Pick<IService, 'images'>[]) => service.find((item) => item.images.length)?.images;

export const getServiceIds = (service: Pick<IService, 'id'>[]) => service.map(({ id }) => id);

export const getServiceHolidayIds = (service: AppointmentServiceType) =>
    service.reduce<number[]>((prev, current) => {
        const holidaysIds = current.schedule?.filter((day) => !day.enable).map(({ id }) => Number(id)) || [];
        return [...prev, ...holidaysIds];
    }, []);

export const getServiceArrayPrice = (service: AppointmentServiceType): number =>
    service.reduce<number>((previousValue, currentValue) => previousValue + (parseFloat(String(currentValue.price)) || 0), 0);

export const getServiceArrayPivotPrice = (service: AppointmentServiceType): number =>
    service.reduce<number>((previousValue, currentValue) => previousValue + (parseFloat(String(currentValue.pivot?.price)) || 0), 0);

export const getServiceArrayPivotPrepay = (service: AppointmentServiceType): number =>
    service.reduce<number>((previousValue, currentValue) => previousValue + (parseFloat(String(currentValue.pivot?.prepay)) || 0), 0);

export const getServiceArrayDuration = (service: AppointmentServiceType, employee?: IEmployee | null): number => {
    const employeeServices = employee?.services ?? [];
    return service.reduce<number>((previousValue, currentValue) => {
        const linkedService = employeeServices.find(({ id }) => id === currentValue.id);

        if (linkedService?.pivot && linkedService?.pivot?.duration !== null) {
            return previousValue + linkedService?.pivot?.duration;
        }

        return previousValue + (currentValue?.duration || 0);
    }, 0);
};

export const isAllServiceVirtual = (service: AppointmentServiceType) =>
    service.length > 0 && service.every((s) => s.location_type === ServiceLocationType.Virtual);

export const isSomeServicesPhone = (service: AppointmentServiceType) =>
    service.some((s) => s.location_type === ServiceLocationType.Callback);

export const isAllServiceExternal = (service: AppointmentServiceType) =>
    service.length > 0 && service.every((s) => s.location_type === ServiceLocationType.External);

export const isAllServicesFree = (service: AppointmentServiceType) => service.every((s) => s.payment_type === PaymentType.Free);

export const isServicesRescheduleEnabled = (service: AppointmentServiceType) => sortServicesArray(service)[0].is_reschedule_enabled;

export const isServicesAutoAssignEnabled = (service: AppointmentServiceType) => service.every((s) => s.staff_autoassign);

export const getNewMultiServicesValue = (newValue: number[], options: IService[]): number[] => {
    const firstId = newValue[0];
    const lastId = [...newValue].pop();
    if (newValue.length > 1 && !!lastId && !!firstId) {
        const firstServiceLocType = options.find(({ id }) => id === firstId)?.location_type;
        const lastServiceLocType = options.find(({ id }) => id === lastId)?.location_type;
        const hasVirtualServices = newValue.some(
            (val) => options.find(({ id }) => id === val)?.location_type === ServiceLocationType.Virtual
        );
        return hasVirtualServices || firstServiceLocType !== lastServiceLocType ? [lastId] : newValue;
    }

    return newValue;
};

export const getDefaultServicePrice = (service: Pick<IService, 'id' | 'price'>, employeeServices: IEmployee['services'] | IService[]) => {
    const linkedService = employeeServices.find(({ id }) => id === service.id);

    if (linkedService?.pivot && linkedService?.pivot?.price !== null) {
        return linkedService?.pivot?.price;
    }

    return service?.price;
};

export const getMaterialsBasedServicePrice = (
    service: Pick<IService, 'id' | 'price' | 'use_materials' | 'materials'>,
    employeeServices: IEmployee['services'] | IService[],
    amount: number | null | undefined
) => {
    const linkedService = employeeServices.find(({ id }) => id === service.id) ?? service;
    const price = getDefaultServicePrice(service, employeeServices);

    if (linkedService?.use_materials && linkedService?.materials && amount) {
        const materialsPrice = amount ? amount * linkedService.materials.price_per_unit : 0;
        const parsedPrice = price ? parseFloat(String(price)) : 0;

        return materialsPrice + parsedPrice;
    }

    return price;
};

export const getCommonServicesLocationIds = (services: IService[]): number[] => {
    const ids = services.map(({ locations }) => (locations ? locations.map(({ id }) => id) : []));
    return getArrayIntersections(ids);
};

export const getCommonServiceProvidersIds = (services: IService[]): number[] => {
    const ids = services.map(({ employees }) => (employees ? employees.map(({ id }) => id) : []));
    return getArrayIntersections(ids);
};

export const getProviderBasedServiceArrayPrice = (services: IService[], provider?: Pick<IEmployee, 'services'> | null) => {
    if (provider) {
        return services.reduce<number>((previousValue, currentValue) => {
            const providerService = provider.services?.find(({ id }) => id === currentValue.id);
            const usedSPrice =
                providerService?.pivot && providerService.pivot?.price !== null ? providerService.pivot.price : currentValue.price;

            return previousValue + (parseFloat(String(usedSPrice)) || 0);
        }, 0);
    }

    return getServiceArrayPrice(services);
};
