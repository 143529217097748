import { FC, useCallback } from 'react';
import PermanentEntityDrawer from '../../../../ui-component/PermanentEntityDrawer';
import EntityDrawerContent from '../../../../ui-component/entity-drawer-layout/EntityDrawerContent';
import EntityDrawerActions from '../../../../ui-component/entity-drawer-layout/EntityDrawerActions';
import { Button } from '@mui/material';
import { ICustomerNoteCreatePayload } from '../../../../models/ICustomer';
import customerAPI from '../../../../services/CustomerService';
import CustomerNoteForm from './CustomerNoteForm';
import useShowSnackbar from '../../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../../store/snackbarReducer';
import { useAppDispatch } from '../../../../hooks/redux';
import { stopSubmitting } from '../../../../store/slices/SubmittingSlice';

type CreateCustomerNoteProps = {
    open: boolean;
    customerId: string | number;
    onClose: () => void;
};

const CreateCustomerNote: FC<CreateCustomerNoteProps> = ({ open, customerId, onClose }) => {
    const [saveNote] = customerAPI.useCreateCustomerNoteMutation();
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();

    const onSubmit = useCallback(
        (formData: ICustomerNoteCreatePayload) => {
            saveNote({ customerId, data: formData })
                .unwrap()
                .then(() => {
                    onClose();
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.message || err.data || JSON.stringify(err)
                    });
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [customerId, dispatch, onClose, saveNote, showSnackbar]
    );

    return (
        <PermanentEntityDrawer open={open} title="New Note" onClose={onClose}>
            <EntityDrawerContent>
                <CustomerNoteForm onSubmit={onSubmit} />
            </EntityDrawerContent>
            <EntityDrawerActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" form="customer-note-form">
                    Create Note
                </Button>
            </EntityDrawerActions>
        </PermanentEntityDrawer>
    );
};

export default CreateCustomerNote;
