import { ICustomerNote, ICustomerNoteCreatePayload } from '../../../../models/ICustomer';
import PermanentEntityDrawer from '../../../../ui-component/PermanentEntityDrawer';
import { FC, useCallback, useMemo } from 'react';
import EntityDrawerContent from '../../../../ui-component/entity-drawer-layout/EntityDrawerContent';
import EntityDrawerActions from '../../../../ui-component/entity-drawer-layout/EntityDrawerActions';
import CustomerNoteForm from './CustomerNoteForm';
import { Button } from '@mui/material';
import useCustomerNoteActions from '../hooks/use-customer-note-actions';
import { startSubmitting } from '../../../../store/slices/SubmittingSlice';
import { useAppDispatch } from '../../../../hooks/redux';

type UpdateCustomerNoteProps = {
    open: boolean;
    customerId: string | number;
    onClose: () => void;
    note?: ICustomerNote;
};

const UpdateCustomerNote: FC<UpdateCustomerNoteProps> = ({ open, customerId, onClose, note }) => {
    const dispatch = useAppDispatch();
    const { onUpdateNote } = useCustomerNoteActions(customerId);

    const noteId = note?.id;
    const handleUpdate = useCallback(
        (formData: ICustomerNoteCreatePayload) => {
            if (noteId) {
                dispatch(startSubmitting());
                onUpdateNote({ ...formData, id: noteId }, onClose);
            }
        },
        [dispatch, noteId, onClose, onUpdateNote]
    );

    const initialValues = useMemo(() => {
        if (note) {
            const { text, images, is_pinned } = note;
            return { text, images, is_pinned };
        }

        return null;
    }, [note]);

    if (!initialValues) {
        return null;
    }
    return (
        <PermanentEntityDrawer open={open} onClose={onClose} title="Edit Note">
            <EntityDrawerContent>
                <CustomerNoteForm onSubmit={handleUpdate} initialValues={initialValues} />
            </EntityDrawerContent>
            <EntityDrawerActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" form="customer-note-form">
                    Save Note
                </Button>
            </EntityDrawerActions>
        </PermanentEntityDrawer>
    );
};

export default UpdateCustomerNote;
