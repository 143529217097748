import { FC, useCallback, useState } from 'react';
import customerAPI from '../../../services/CustomerService';
import EntityDrawerContainer from '../../../ui-component/entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from '../../../ui-component/entity-drawer-layout/EntityDrawerHeader';
import EntityDrawerContent from '../../../ui-component/entity-drawer-layout/EntityDrawerContent';
import LoadingSpinner from '../../../ui-component/LoadingSpinner';
import EntityDrawerActions from '../../../ui-component/entity-drawer-layout/EntityDrawerActions';
import { Button, Stack } from '@mui/material';
import CreateCustomerNote from './components/CreateCustomerNote';
import CustomerNoteItem from './components/CustomerNoteItem';
import UpdatedStyleWrapper from '../../../ui-component/updated-style-wrapper';
import { ICustomerNote } from '../../../models/ICustomer';
import UpdateCustomerNote from './components/UpdateCustomerNote';

type CustomerNotesPropsType = {
    customerId: string | number;
    onClose: () => void;
};

const CustomerNotes: FC<CustomerNotesPropsType> = ({ customerId, onClose }) => {
    const [creating, setCreating] = useState(false);
    const [editingNote, setEditingNote] = useState<ICustomerNote | null>(null);

    const handleClose = useCallback(() => {
        setCreating(false);
        setEditingNote(null);
    }, []);

    const { data, isLoading, isFetching } = customerAPI.useGetCustomerNotesQuery(customerId);
    return (
        <UpdatedStyleWrapper>
            <EntityDrawerContainer>
                <EntityDrawerHeader title="Customer Notes" onClose={onClose} />
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <EntityDrawerContent>
                            {isLoading && <LoadingSpinner />}
                            {!isLoading && data ? (
                                <Stack spacing={2} sx={{ opacity: isFetching ? 0.5 : 1 }}>
                                    {data.data.map((item) => (
                                        <CustomerNoteItem key={item.id} note={item} customerId={customerId} onEdit={setEditingNote} />
                                    ))}
                                </Stack>
                            ) : null}
                        </EntityDrawerContent>
                        <EntityDrawerActions>
                            <Button variant="text" color="primary" className="forcedBg" onClick={() => setCreating(true)}>
                                Add Note
                            </Button>
                        </EntityDrawerActions>
                    </>
                )}

                <CreateCustomerNote open={creating} customerId={customerId} onClose={handleClose} />
                <UpdateCustomerNote open={!!editingNote} customerId={customerId} note={editingNote ?? undefined} onClose={handleClose} />
            </EntityDrawerContainer>
        </UpdatedStyleWrapper>
    );
};

export default CustomerNotes;
