import { FC, useCallback, useState } from 'react';
import { Stack, TableCell, TableRow, Checkbox, Button, Box, IconButton } from '@mui/material';
import { ICustomer } from '../../../models/ICustomer';
import CustomerSearch from '../../customer-search';
import CBTable from '../../tables/CBTable';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import CbPortal from '../../cb-portal';
import PermanentEntityDrawer from '../../PermanentEntityDrawer';
import EntityDrawerContent from '../../entity-drawer-layout/EntityDrawerContent';
import useCreateCustomer from '../../../hooks/use-create-customer';
import useDuplicateCustomerConfirm from '../../../hooks/use-duplicate-customer-confirm';
import CustomerForm from '../../CustomerForm';
import useCustomerFormDefaults from '../../../views/customer/hooks/use-customer-form-defaults';
import ExistingCustomerAlert from '../../ExistingCustomerAlert';
import EntityDrawerActions from '../../entity-drawer-layout/EntityDrawerActions';
import { useAppDispatch } from '../../../hooks/redux';
import { stopSubmitting } from '../../../store/slices/SubmittingSlice';
import { RecurringEventCustomerPayload } from '../../../models/IRecurringEvent';

type RecurringEventCustomersProps = {
    value: Array<RecurringEventCustomerPayload & { title: string }>;
    onChange: (v: { id: number; is_paid: boolean }[]) => void;
};

const RecurringEventCustomers: FC<RecurringEventCustomersProps> = ({ value, onChange }) => {
    const dispatch = useAppDispatch();
    const [creating, setCreating] = useState(false);
    const [adding, setAdding] = useState(false);
    const { data: customerErrorData, handleCustomerError, existingCustomer, clearError } = useDuplicateCustomerConfirm();

    const handleCreateFormClose = useCallback(() => {
        setCreating(false);
        clearError();
    }, [clearError]);

    const onAddCustomer = useCallback(
        (customer: ICustomer | null) => {
            setAdding(false);
            if (customer && !value.some(({ id }) => id === customer.id)) {
                const { firstname, lastname } = customer;
                onChange([...value, { id: customer.id, is_paid: false, title: `${firstname} ${lastname}` }]);
            }
        },
        [onChange, value]
    );

    const onDeleteCustomer = useCallback(
        (id: number) => {
            const newCustomers = [...value].filter((customer) => customer.id !== id);
            onChange(newCustomers);
        },
        [value, onChange]
    );

    const handleTogglePaid = useCallback(
        (id: number, newValue: boolean) => {
            const newCustomers = value.map((customer) =>
                customer.id === id
                    ? {
                          ...customer,
                          is_paid: newValue
                      }
                    : customer
            );
            onChange(newCustomers);
        },
        [value, onChange]
    );

    const handleCustomerCreationCb = useCallback(
        (customer: ICustomer) => {
            setAdding(false);
            handleCreateFormClose();
            onAddCustomer(customer);
            dispatch(stopSubmitting());
        },
        [dispatch, handleCreateFormClose, onAddCustomer]
    );

    const handleCreateCustomer = useCreateCustomer(handleCustomerCreationCb, handleCustomerError);
    const defaults = useCustomerFormDefaults(existingCustomer);

    return (
        <Stack spacing={1}>
            <CBTable
                compact
                rows={value}
                columns={[
                    { id: 'is_paid', label: 'Paid' },
                    { id: 'name', label: 'Customer Name' },
                    { id: 'actions', label: '' }
                ]}
                renderRow={(item) => (
                    <TableRow key={item.id}>
                        <TableCell width={60}>
                            <Checkbox
                                value={item.is_paid}
                                checked={item.is_paid}
                                onChange={(_, checked) => handleTogglePaid(item.id, checked)}
                            />
                        </TableCell>
                        <TableCell>{item.title}</TableCell>
                        <TableCell align="right">
                            <Button
                                color="error"
                                size="small"
                                onClick={() => onDeleteCustomer(item.id)}
                                className="forcedBg Cb-Table-Btn"
                                variant="text"
                            >
                                Remove
                            </Button>
                        </TableCell>
                    </TableRow>
                )}
            />
            {adding ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <CustomerSearch
                        customer={null}
                        onChange={onAddCustomer}
                        clearAfterSelect
                        excludedIds={value.map(({ id }) => id)}
                        onSelectNewOption={(v) => handleCreateCustomer(v)}
                    />
                    <IconButton color="primary" onClick={() => setCreating(true)}>
                        <AddIcon />
                    </IconButton>
                </Stack>
            ) : (
                <Button
                    color="primary"
                    variant="text"
                    className="forcedBg"
                    endIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={() => setAdding(true)}
                    sx={{ alignSelf: 'flex-start' }}
                >
                    Add
                </Button>
            )}

            <CbPortal query="#newCustomerFormContainer">
                <PermanentEntityDrawer title="Create Customer" open={creating} onClose={handleCreateFormClose}>
                    <EntityDrawerContent>
                        <CustomerForm
                            customer={defaults}
                            onSubmit={(formData) =>
                                handleCreateCustomer({
                                    ...formData,
                                    id: existingCustomer?.id
                                })
                            }
                            formId="new-customer-form"
                            hideTitles
                            skipChangesCheck
                            isNew
                        />

                        {customerErrorData ? (
                            <Box pt={2}>
                                <ExistingCustomerAlert email={customerErrorData?.email} phone={customerErrorData?.phone} />
                            </Box>
                        ) : null}
                    </EntityDrawerContent>
                    <EntityDrawerActions>
                        <Button onClick={handleCreateFormClose} variant="text" className="forcedBg">
                            Cancel
                        </Button>
                        <Button color="primary" variant="contained" type="submit" form="new-customer-form">
                            {existingCustomer ? 'Update & Add' : 'Create New'}
                        </Button>
                    </EntityDrawerActions>
                </PermanentEntityDrawer>
            </CbPortal>
        </Stack>
    );
};

export default RecurringEventCustomers;
